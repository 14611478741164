import React from 'react'
import { Link } from 'gatsby'
import ReactWOW from 'react-wow'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion'

const Salaries = () => {
    return (
        <section className="solutions-area ptb-70 bg-wspk">
            <div className="container">
                <div className="row align-items-center pb-30">
                    <div className="col-lg-12 col-md-12 pt-70 pb-30 center">
                        <h2 className="subtitle">You are an employee or a collaborator</h2>
                    </div>

                        <div className="col-lg-6 col-md-6">
                            <ReactWOW delay='.1s' animation='fadeInRight'>
                                <div className="btn-box section-title pt-50">
                                    <p>Access your account to file a report, follow the treatment, exchange with the referent in anonymous or confidential mode.</p>
                                    <Link to="https://agir.wispeek.com" className="default-btn">
                                        <i className="flaticon-right"></i> 
                                        Employee area <span></span>
                                    </Link>
                                </div>
                            </ReactWOW>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <ReactWOW delay='.1s' animation='fadeInRight'>
                                <div className="btn-box section-title pt-50">
                                    <p>You do not want to create an account, this form will be encrypted and sent to the referrer and you will be asked for your identity.</p>
                                    <Link to="https://gerer.wispeek.com/s/3quiPDPshkBw4dKrt" target="_blank" className="default-btn" style={{backgroundColor:'coral'}}>
                                        <i className="flaticon-right"></i> 
                                        Make an alert <span></span>
                                    </Link>
                                </div>
                            </ReactWOW>
                        </div>
                    </div>

                <div className="faq-accordion pt-50 pb-70">
                    <Accordion allowZeroExpanded>
                            
                        <AccordionItem uuid="a">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q1. Why create an account ?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                            <p><span className='bold'>The creation of the account is anonymous:</span> your email address and password are not saved and communicated. The company code is provided to you by your organization.</p>
                            <p className='bold'>Create your account</p>
                            <p>This interface is personal, only you can access it. Be careful to remember your password and not disclose it to anyone.</p>

                            <p>Your account allows you to :
                                <ul>
                                    <li>Make reports and feedback in confidential or anonymous mode</li>
                                    <li>Chat via a secure messaging with the referent in charge of your file and exchange all types of documents</li>
                                    <li>Consult the documents available to you (company charter, procedures, forms, etc.).</li>
                                    <li>Keep your password, as the data is encrypted, we cannot renew it.</li>
                                </ul>
                            </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                            
                        <AccordionItem uuid="b">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q2. Commitment Protection and Responsibility?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                            <p>Wispeek is an internal reporting platform that complies with the legal obligations to protect whistleblowers under Sapin 2, Waserman or the duty of care.</p>
                            <p><span className='bold'>Définition :</span> A whistleblower is a natural person who, without direct financial compensation and in good faith, provides information about a crime, reports or discloses an offence, a threat or harm to the public interest a violation of an international undertaking duly ratified or approved by France or of a unilateral act of an international organization concluded on the basis of such an undertaking of the law or the ordinance. If the information was not collected in the course of the professional activities referred to in Article 8, paragraph 1, the informant must have personal knowledge of it.</p>
                            <p><span className='bold'>Procedure :</span>  As soon as you submit your report confidentially or anonymously, you automatically receive an acknowledgement of receipt. Your company must then respond to you in writing via secure messaging within 3 months to inform you of the follow-up of your report.</p>
                            <p><span className='bold'>Protection of whistleblowers :</span> Wispeek guarantees the confidentiality of your identity and the identity of said persons and the information you disclose.</p>
                            <p className='pt-30'>We recommend that you reveal your identity in order to optimize the process and ensure your protection. However, if you wish to remain anonymous, Wispeek guarantees your anonymity.</p>
                            <p>To combat the risk of retaliation, your exchanges are encrypted and only the correspondent can consult them. In the case of an admissible report, the informant is protected against internal disciplinary measures and civil or criminal convictions.</p>
                            </AccordionItemPanel>
                        </AccordionItem>

                    </Accordion>         
                </div>
            </div>
        </section>
    )
}

export default Salaries;