import React from "react"
import Layout from "../components/App/Layout"
import Salaries from "../components/Index/Salaries.en"
import Navbar from "../components/App/Navbar.en"
import Banner from "../components/Index/Banner.en"

const Home = () => {
  return (
    <Layout>
      <Navbar />
      <Banner />
      <div id="salaries"></div>
      <Salaries />
    </Layout>
  )
}

export default Home