import React from 'react'
import ReactWOW from 'react-wow'
import { Link } from 'gatsby'
import wispeek from '../../assets/images/home/wispeek.png'
import { AnchorLink } from "gatsby-plugin-anchor-links"


const Banner = () => {
    return (
        <div className="it-services-banner" style={{marginBottom:'50px',marginTop:'20px'}}>
            <div className="container">
                <div className="row align-items-center pt-70">
                    <div className='col-lg-12 col-md-12 center'>
                        <h1 className='title-home' style={{fontSize:'2rem'}}>
                            Internal ethics<br></br>
                            reporting platform
                        </h1>
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="project-start-image pt-30">
                                <img width='50%' src={wispeek} alt="wispeek mutli-devices" />
                            </div>
                        </ReactWOW>
                    </div>
                    <div className="col-lg-6 col-md-12 center pt-10">
                        <div className="banner-wrapper-content">
                            <ReactWOW delay='.1s' animation='fadeInRight'>
                                <div className="btn-box">
                                    <p>Are you an employee or collaborator ?</p>
                                    <AnchorLink to="/en#salaries" className="default-btn">
                                        <i className="bx bx-chevron-right"></i> 
                                        my wispeek area <span></span>
                                    </AnchorLink>
                                </div>
                            </ReactWOW>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12 center pt-10">
                        <div className="banner-wrapper-content">
                            <ReactWOW delay='.1s' animation='fadeInRight'> 
                                <div className="btn-box">
                                    <p>are you a referent, supervisor?</p>
                                    <Link to="/en/referents" className="default-btn btn-custom">
                                        <i className="bx bx-chevron-right"></i> 
                                        referent area <span></span>
                                    </Link>
                                </div>
                            </ReactWOW>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner